import React from "react"

import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Columns, Section } from "../components/Containers"
import { Text } from "../components/Core"
import { Heading } from "../components/Hero"
import { Button } from "../components/Button"

const VirtualConsult = ({ data, pageContext }) => {
  const { metaTitle, metaDescription, heading, content } =
    data.allUniquePagesJson.nodes[0]
  const language = pageContext.language

  return (
    <Layout className="virtual-consult" navSpacer language={language}>
      <SEO title={metaTitle} description={metaDescription} lang={language} />

      <Heading heading={heading} />

      <Section>
        <Columns sideColumnsSize={3}>
          <div className="column">
            <div className="px-40--mobile">
              <Button
                href="https://consultation.77plasticsurgery.com/procedures"
                contained
                buttonText="Book Your Virtual Consultation"
                destination="external"
                className="mx-auto mt-0 mb-3 virtual-consult__button"
              />
            </div>
            <Text text={content} />

            <div className="px-40--mobile">
              <Button
                href="https://consultation.77plasticsurgery.com/procedures"
                contained
                buttonText="Book Your Virtual Consultation"
                destination="external"
                className="mx-auto virtual-consult__button"
              />
            </div>
          </div>
        </Columns>
      </Section>
    </Layout>
  )
}

export const VirtualConsultPageQuery = graphql`
  query VirtualConsultQuery($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaDescription
        metaTitle
        heading
        content
      }
    }
  }
`

export default VirtualConsult
